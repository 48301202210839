import 'intersection-observer'
import * as scrollama from 'scrollama'

export default class Inview {
  constructor() {
    const scroller = scrollama()
    scroller
      .setup({
        step: '.js-inview',
        offset: 0.7,
        once: true,
      })
      .onStepEnter(this.handleStepEnter)
      .onStepExit(this.handleStepExit)

    window.addEventListener('resize', scroller.resize)
    scroller.resize
  }

  handleStepEnter(response) {
    // response = { element, direction, index }
    // console.log(response)
    response.element.classList.add('is-inview')
  }

  handleStepExit(response) {
    // response = { element, direction, index }
    // console.log(response)
    // response.element.classList.remove('is-inview')
  }
}
