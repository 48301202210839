export default class FixedLabel {
  target: HTMLElement
  isDown: boolean
  prevScroll: number

  constructor(target) {
    this.target = target
    this.isDown = false
    this.prevScroll = 0

    this.render = this.render.bind(this)
    this.render()
  }

  render() {
    const scroll = document.documentElement.scrollTop || document.body.scrollTop
    if (scroll > this.prevScroll && !this.isDown) {
      this.isDown = true
      this.target.classList.remove('is-visible')
    } else if (scroll <= this.prevScroll && this.isDown) {
      this.isDown = false
      this.target.classList.add('is-visible')
    }
    this.prevScroll = scroll
    requestAnimationFrame(this.render)
  }
}
