export default class FadeRotate {
  target: HTMLElement
  items: HTMLCollection
  index: number
  prevIndex: number
  itemTotal: number

  constructor(target) {
    this.target = target
    this.items = this.target.children
    this.itemTotal = this.items.length
    this.index = 0
    this.prevIndex = 0

    this.setClass = this.setClass.bind(this)
    this.setClass()
    setInterval(this.setClass, 5000)
  }

  setClass() {
    this.items[this.prevIndex].classList.remove('is-active')
    this.items[this.index].classList.add('is-active')
    this.prevIndex = this.index
    this.index++
    if (this.index >= this.itemTotal) this.index = 0
  }
}
