export default class HeaderMenu {
  isOpen: boolean

  constructor() {
    this.isOpen = false

    this.open = this.open.bind(this)
    this.close = this.close.bind(this)

    this.setHandler()
  }

  setHandler() {
    document
      .querySelector('.js-header-menu-open')
      .addEventListener('click', this.open)

    const $jsHeaderMenuClose = document.querySelectorAll(
      '.js-header-menu-close',
    )
    for (let i = 0; i < $jsHeaderMenuClose.length; i++) {
      $jsHeaderMenuClose[i].addEventListener('click', this.close)
    }
  }

  open() {
    console.log('click')
    document.body.classList.add('js-header-menu-is-open')
  }

  close() {
    document.body.classList.remove('js-header-menu-is-open')
  }
}
