export default class Accordion {
  target: HTMLElement
  button: HTMLElement
  isOpen: boolean

  constructor(target) {
    this.target = target
    this.button = this.target.querySelector('.js-accordion__button')
    this.isOpen = this.target.classList.contains('js-accordion-is-open')

    this.toggle = this.toggle.bind(this)
    this.button.addEventListener('click', this.toggle)
  }

  toggle() {
    if (this.isOpen) {
      this.target.classList.remove('js-accordion-is-open')
      this.isOpen = false
    } else {
      this.target.classList.add('js-accordion-is-open')
      this.isOpen = true
    }
  }
}
