import 'lazysizes'
import HeaderMenu from './header-menu'
import Share from './share'
import Swiper from 'swiper'
import FixedLabel from './fixed-label'
import Accordion from './accordion'
import Invew from './inview'
import FadeRotate from './fade-rotate'

new HeaderMenu()
const swiper = new Swiper('.swiper-container', {
  slidesPerView: 2,
  spaceBetween: 10,
  scrollbar: {
    el: '.swiper-scrollbar',
    draggable: true,
    hide: false,
    snapOnRelease: true,
    dragSize: 'auto',
  },
  breakpoints: {
    768: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
  },
})

// console.log("test")

// if ("loading" in HTMLImageElement.prototype) {
//   alert("ネイティブレイジーロードに対応する")
// } else {
//   alert("対応しない")
// }

const share = new Share()
const url = location.href
const title = document.title

const $jsShare = document.querySelectorAll('.js-share')
for (let i = 0; i < $jsShare.length; i++) {
  const type = $jsShare[i].getAttribute('data-type')
  if (type === 'facebook')
    $jsShare[i].addEventListener('click', () => {
      console.log('click')
      share.facebook(url)
    })
  if (type === 'twitter')
    $jsShare[i].addEventListener('click', () => share.twitter(url, title))
}

const $jsFixedLabel = document.querySelectorAll('.js-fixed-label')
for (let i = 0; i < $jsFixedLabel.length; i++) {
  new FixedLabel($jsFixedLabel[i])
}

const $jsAccordion = document.querySelectorAll('.js-accordion')
for (let i = 0; i < $jsAccordion.length; i++) {
  new Accordion($jsAccordion[i])
}

if (document.querySelector('.js-inview')) new Invew()

const $jsFadeRotate = document.querySelectorAll('.js-fade-rotate')
for (let i = 0; i < $jsFadeRotate.length; i++) {
  new FadeRotate($jsFadeRotate[i])
}
