class Share {
  facebook(url) {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
      null,
      'width=600, height=400, scrollbars=yes',
    )
  }

  twitter(url, title) {
    window.open(
      `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        url,
      )}&text=${encodeURIComponent(title)}`,
      null,
      'width=600, height=400, scrollbars=yes',
    )
  }

  line(url, title) {
    window.open(
      `https://timeline.line.me/social-plugin/share?url=${encodeURIComponent(
        url,
      )}&text=${encodeURIComponent(title)}`,
      null,
      'width=600, height=400, scrollbars=yes',
    )
  }
}

export default Share
